
.evaluate {
    padding: 0 20px;
    padding-bottom: 120px;
    box-sizing: border-box;
    overflow-y: auto;
    height: 100vh;

    .evaluate-item {
        width: 100%;

        border-radius: 4px;
        border-bottom: 1px solid #E3E2E5;
        margin-top: 20px;

        .item-header {
            height: 60px;
            background-color: #F2F2F6;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            box-sizing: border-box;

            .button {
                color: #317BE9;
                cursor: pointer;
            }

            .evaluate-info {
                display: flex;

                .order-number {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }

        .item-content {
            width: 100%;
            height: 100%;
            display: flex;

            .goodsinfo {
                width: 600px;
                // background-color: pink;
                border-right: 1px solid #D9D9D9;
                padding: 20px;
                box-sizing: border-box;
                display: flex;
                align-items: flex-start;

                .goods-img {
                    width: 80px;
                    height: 80px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover; 
                    }
                   
                }

                .goods-name {
                    margin-left: 30px;
                    box-sizing: border-box;
                    width: 100%;
                    p {
                        margin: 0;
                        padding: 0px !important;
                        font-size: 14px;
                        color: #0B0B0B;
                        font-weight: medium;
                    }
                    .good-name{
                        width: 90%;
                        white-space: nowrap; /* 文本在一行显示 */
                        overflow: hidden; /* 超出部分隐藏 */
                        text-overflow: ellipsis; /* 显示省略号 */
                    }
                }
            }

            .review {
                flex: 1;
                padding: 20px 0;
                margin-left: 20px;
                font-size: 14px;
                box-sizing: border-box;

                .noData {

                    p {
                        margin: 0;
                        padding: 0px !important;
                    }

                    .no-review {
                        color: #999999;
                    }

                    .user-name {
                        color: #35323B;
                    }
                }

                .hasData {
                    .has-content{
                        padding: 0;
                        margin: 0;
                        color: #0B0B0B;
                        font-weight: medium;
                    }
                    .goodes-img {
                        display: flex;
                        margin-bottom: 20px;
                        margin-top: 10px;

                        .show-img {
                            width: 100px;
                            height: 100px;
                            border-radius: 4px;
                            margin-right: 10px;
                            img{
                                width: 100%; /* 图片宽度占满容器 */
                                height: 100%; /* 图片高度占满容器 */
                                object-fit: cover; /* 图片不变形，占满整个盒子 */
                                border-radius: 4px;
                            }
                           
                        }

                    }


                }

            }
        }
    }
}

.input-bk {
    background-color: #F5F6F8;
    height: 150px;
    padding: 20px;
    box-sizing: border-box;

    .send-bottom {
        display: flex;
        margin-top: 10px;
        justify-content: flex-end;

        .send-button {
            background-color: #1AB163;
            color: #FFFFFF;
            border-radius: 6px;

        }
    }

}

.show-view {
    color: #666666;
    font-size: 14px;

    .review-num {
        text-align: center;
        border-bottom: 1px solid #D9D9D9;
        padding-bottom: 10px;
    }

    div {
        .merchant_review {
            display: flex;
            align-items: flex-start;

            span {
                margin-right: 10px;
                white-space: nowrap;
            }

            p {
                padding: 0;
                margin: 0;
            }
        }

        .review_item_date {
            color: #999999;
            font-size: 14px;
        }
    }
}
.big-img{
    width: 100%;
    height: 500px;
    // background-color: pink;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

::v-deep .el-textarea__inner {
    height: 70px;
}
.evaluateStart{
    display: flex;
    align-items: center;
    color: #35323B;
    font-size: 14px;
}
.span-right{
    margin-right: 5px;
}

.start1 {
    width: 20px;
    height: 20px;
}

.start {
    width: 20px;
    height: 20px;
}
.pagination{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
